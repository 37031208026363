@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&family=Poppins:wght@300;400;700&family=Roboto:wght@300;400;700");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: Rubik;
    src: url(./assets/font/rubik/RubikWetPaint.ttf) format("woff");
  }
  @font-face {
    font-family: Affectionately Yours;
    src: url(./assets/font/affectionately-yours/AffectionatelyYours.ttf)
      format("woff");
  }
  @font-face {
    font-family: Cheri;
    src: url(./assets/font/cheri/cheri.ttf) format("woff");
  }
}
